.form-type-select {
  display: inline-block !important;
}

.selectize-control.single {
  display: block;

  > .selectize-input {
    border: none;
    border-bottom: 1px solid #A6A6A6;
    border-radius: 0;
    background: none;
    padding-left: 0;
    display: block;

    &:before {
      content: none !important;
    }

    &:after {
      left: calc(100% - 10px);
      right: auto;
    }

    &.input-active {
      display: block;
      background: none;
    }
  }

  .selectize-dropdown {
    border: 1px solid #A6A6A6;
    box-shadow: none;
    //border-top: none;
    border-radius: 0;

    .selectize-dropdown-content {
      > .option {
        display: block;
        cursor: pointer;

        &.selected {
          background-color: #f5fafd;
          color: #495c68;
        }
      }
    }
  }

}