$region-color: #908267;
$row-bg: wheat;

.layout-componer-container {
  width: 100%;
  height: 100%;

  &.closed {
    display: none;

    & + .node {
      display: block;
    }
  }

  &:not(.closed) {

    .layout-content {
      border: 1px solid #c5c5c5;
      margin-bottom: 15px;

      &:last-child {
        margin-bottom: 0;
      }

    }

    .layout-content > .specific-component,
    .layout-content > .view,
    .layout-content > .entity-paragraphs-item,
    .layout-content > .paragraphs-item {
      zoom: .7;
      background: white;
      min-height: 100px;
      position: relative;
      padding-top: 36px !important;
      box-sizing: border-box;

      &:before {
        content: "";
        display: block;
        width: 100%;
        height: 36px;
        background: url(image-url("icon-move.png")) no-repeat top 3px right 6px #EFEBEB;
        background-size: 25px;
        border-bottom: 1px solid #c5c5c5;
        cursor: pointer;
        position: absolute;
        top: 0;
        right: 0;
      }

    }
  }

  .layout-stuff {
    background: $row-bg;
    width: 30%;
    height: 100%;

    &.sticky {
      position: fixed;
      top: 40px;
      z-index: 99;

      .layout-available-contents {
        overflow-y: auto;
        overflow-x: hidden;
      }
    }
  }

  .layout-configurator {

    padding: 10px;

    > h2 {
      font-size: 14px;
      line-height: normal;
      margin: 0 0 10px;
    }
  }

  // Remove link (of a row)
  .remove-row {
    width: 20px;
    height: 20px;
    background: url(image-url("icon-close-white.png")) no-repeat center #616161;
    background-size: 10px;
    color: white;
    border-radius: 50%;
    text-align: center;
    line-height: normal;
    text-indent: -10000em;
    position: absolute;
    right: -10px;
    top: -10px;
  }

  // Generic element. Is a visual helper
  // for dragging elements.
  .helper-add-row {
    background: $row-bg;
    width: 100px !important;
    height: 100px !important;
    border-radius: 5px;
  }

  .empty-message {
    min-height: 100px;
    background: #cebd9e;
    border: 3px dashed #b3a58a;
    margin: 10px;
    text-align: center;
    line-height: 92px;
    font-size: 20px;

    &.hide {
      display: none;
    }
  }

  // The layout handler. Here the
  // uses can view it's art.
  > .layout {
    background: #fff;
    margin: 0;
    padding: 1px;
    width: 70%;
    min-height: 100px;
    height: 100%;
    float: right;

    .reveal-grid {
      margin-bottom: 10px;

      > .col {
        min-height: 100px;
        background: #fff;
        border: 2px dashed #b3a58a;
      }
    }

    > .row {
      margin: 10px;
      position: relative;

      > .col {
        //background: $region-color;
        //border-radius: 3px;

        // The user is dragging an existent row.
        > .ui-sortable-helper {

        }

        > .ui-state-highlight {
          min-height: 100px;
          background: #cebd9e;
          border: 3px dashed #b3a58a;
          margin: 10px;
        }

      }
    }

    // Highlighted place where used is going
    // to drop an existent or new row.
    > .ui-state-highlight {
      min-height: 100px;
      background: #cebd9e;
      border: 3px dashed #b3a58a;
      margin: 10px;
    }

    // The user is dragging an existent row.
    > .ui-sortable-helper {
      opacity: .5;
    }

  }

  .layout-available-contents {
    background: $row-bg;
    padding: 10px;
    max-height: 500px;
    overflow-y: scroll;

    > h2 {
      font-size: 14px;
      line-height: normal;
      margin: 0 0 10px;
    }

    > .layout-contents {
      min-height: 100px;

      // Highlighted place where used is going
      // to drop an existent or new row.
      > .ui-state-highlight {
        min-height: 100px;
        background: #cebd9e;
        border: 3px dashed #b3a58a;
        margin: 10px;
      }

      // The component
      > .layout-content {
        overflow: hidden;
        border: 1px solid #c5c5c5;
        margin-bottom: 10px;

        &:last-child {
          margin-bottom: 0;
        }

        // When the user drops a new element to the layout we need control
        // the appearance of the dropped element.
        &.ui-sortable-helper {
          opacity: .5;
        }

        > div {
          position: relative;
          padding-top: 36px;
          box-sizing: border-box;

          &:before {
            content: "";
            display: block;
            width: 100%;
            height: 36px;
            background: url(image-url("icon-move.png")) no-repeat top 3px right 6px #EFEBEB;
            background-size: 25px;
            border-bottom: 1px solid #c5c5c5;
            cursor: pointer;
            position: absolute;
            top: 0;
            right: 0;
          }

        }
      }
    }

    .entity-paragraphs-item,
    .paragraphs-item {
      zoom: .5;
      min-height: 140px;
    }

  }

  // Save and discard buttons
  .layout-action {

    button {
      display: block;
      padding: 10px;
      margin: 0 0 10px;
      width: 100%;

      &:last-child {
        margin-bottom: 0;
      }

    }
  }

  // Available columns to add to the layout.
  .layout-regions {
    margin-bottom: 30px;

    .row {
      //margin-bottom: 10px;
      //background: $row-bg;
      //padding: 4px;

      // The user is dragging an new row.
      &.ui-draggable-dragging {
        opacity: .5;
      }

      // When the user drops a new element to the layout we need control
      // the appearance of the dropped element.
      &.ui-sortable-helper {
      }

    }

    .reveal-grid {
      margin-bottom: 10px;

      > .col {
        min-height: 30px;
        background: #908267;
        border: 2px dashed #b3a58a;
        margin-bottom: 0;
      }

    }
  }

}

.allocate-components-message {
  text-align: center;
  padding: 20px;
  background: #e6e6f5;
  margin: 50px;

  h1 {
    margin-top: 10px;
  }

  a {
    background: #0da314;
    padding: 3px 10px;
    color: white;
  }

}
