#map-spain {
  width: 500px;
  height: 500px;

  .ccaa {
    &:hover {
      fill: red; //usar el rojo de sm
      cursor: pointer;
    }
  }

  .ccaa-group {
    &:hover {
      > * {
        fill: red; //usar el rojo de sm
        cursor: pointer;
      }
    }
  }
}
