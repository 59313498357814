.view-webform-results {

  .view-header {
    .webform-results-per-page {
      padding: 10px;
    }
  }

  .view-content {

    .sticky-header {
      top: 40px !important;
      z-index: 2;
    }

    table {
      tr {
        th {
          padding: 10px;
        }
      }

      tr {

        &.even {
          background-color: white;
          border-bottom: none;
        }

        &.odd {
          background-color: $color-grey-4;
          border-bottom: none;
        }

        td {
          padding: 10px;
        }
      }
    }
  }
}