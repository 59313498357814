table.translation-node-table {
  tr {
    th {
      padding: 10px;
    }
  }
  tr {

    &.even {
      background-color: white;
      border-bottom: none;
    }

    &.odd {
      background-color: $color-grey-4;
      border-bottom: none;
    }

    td {
      padding: 10px;
    }
  }
}