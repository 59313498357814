body.sm-role {
  padding-top: 39px;

  a.tabledrag-handle .handle {
    width: 24px;
    height: 24px;
    margin: -0.1em 0.9em;
    padding: 0.42em 0.5em;
  }

  textarea,
  .cke_reset.cke_chrome {
    border: 1px solid;
  }

  fieldset {
    margin: 20px 0;
  }

  .form-actions {
    display: flex;

    input, a {
      color: white;
      font-size: 16px;
      font-weight: 500;
      background-color: #494949;
      border: none;
      cursor: pointer;
      transition: all 250ms;
      padding: 8px 20px;
      margin-right: 10px;
      order: 10;

      &:hover {
        opacity: .8;
      }

    }

    #edit-submit {
      color: white;
      background: $color-green-1;
      order: 0;
    }

    #edit-delete{
      color: white;
      background: $color-red-3;
      order: 2;
    }

    #edit-cancel {
      order: 1;
    }

  }

}
