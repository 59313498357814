// Color's
////////////////

// Grey
$color-grey-1: #4D4D4D;
$color-grey-2: #A6A6A6;
$color-grey-3: #CDCBCD;
$color-grey-4: #F3F2F3;
$color-grey-5: #F4F4F4;

// Red
$color-red-1: #FF4C4C;
$color-red-2: rgba(255, 76, 76, .8);
$color-red-3: #EE0000;

// Purple
$color-purple-1: #C78EBF;

// Yellow
$color-yellow-1: #FFD530;

// Green
$color-green-1: #61B732;
$color-green-2: #C7DC69;

// Blue
$color-blue-1: #354D5F;
$color-blue-2: #6DCEF5;
$color-blue-3: #59C4C6;