#menu-overview-form {

  .form-submit {
    color: white;
    font-size: 16px;
    font-weight: 500;
    background: $color-grey-1;
    border: none;
    cursor: pointer;
    padding: 8px 20px;
    margin-top: 1em;

    &[disabled] {
      background: $color-grey-2;
    }

    &:not([disabled]):hover {
      background: $color-red-1;
    }

    &.alert {
      background: $color-red-1;

      &:hover {
        background: $color-grey-1;
      }

    }

  }

  table {
    tr {
      th {
        padding: 10px;
      }
    }
    tr {

      &.even {
        background-color: white;
        border-bottom: none;
      }

      &.odd {
        background-color: $color-grey-4;
        border-bottom: none;
      }

      td {
        padding: 10px;
      }
    }
  }
}
