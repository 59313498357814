ul.tabs.primary {
  margin: 20px 0 0;

  li {
    display: inline-block;

    a {
      display: block;
      font-size: 16px;
      font-weight: inherit;
      line-height: inherit;
      padding: 10px;
      margin: 0;
    }

  }

}
