.view-comments-moderation {
  .vbo-views-form {

    .form-item label {
      margin-bottom: 7px;
    }

    .form-type-select {
      margin: 0;
    }

    .selectize-control.single {
      min-width: 300px;
    }

    .form-submit {
      color: white;
      font-size: 16px;
      font-weight: 500;
      background: $color-grey-1;
      border: none;
      cursor: pointer;
      padding: 8px 20px;
      vertical-align: top;

      &[disabled] {
        background: $color-grey-2;
      }

      &:not([disabled]):hover {
        background: $color-red-1;
      }

      &.alert {
        background: $color-red-1;

        &:hover {
          background: $color-grey-1;
        }

      }

    }

    form {

      > div > fieldset,
      > div > div {
        margin-top: 1em;
        margin-bottom: 1em;
      }

      fieldset {

        &.collapsed {

          legend a {
            color: $color-red-1;
          }

        }
      }

    }

    .form-actions {

      input {
        margin-right: 15px;

        &:last-child {
          margin-right: 0;
        }

      }

    }
  }

  .view-content {

    .sticky-header {
      top: 40px !important;
      z-index: 2;
    }

    .fieldset-wrapper {
      > * {
        margin-right: 10px;
      }
    }

    table {
      tr {
        th {
          padding: 10px;
        }
      }

      tr {

        &.even {
          background-color: white;
          border-bottom: none;
        }

        &.odd {
          background-color: $color-grey-4;
          border-bottom: none;
        }

        td {
          padding: 10px;

        }
      }
    }
  }
}
