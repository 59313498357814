.cke_editable {
  padding: 10px;

  // Makes white font text visible.
  [style="color:#ffffff;"] {
    background: #000000;
    position: relative;
    padding: 0 3px;
    margin-top: 5px;
    display: inline-block;

    &:before {
      position: absolute;
      content: "Texto blanco";
      color: white;
      bottom: 100%;
      left: 0;
      font-size: 10px;
      background: #5f5f5f;
      padding: 1px 5px;
    }
  }

  // Makes light grey font text visible.
  [style="color:#f3f2f3;"] {
    background: #000000;
    position: relative;
    padding: 0 3px;
    margin-top: 5px;
    display: inline-block;

    &:before {
      position: absolute;
      content: "Texto gris claro";
      color: white;
      bottom: 100%;
      left: 0;
      font-size: 10px;
      background: #5f5f5f;
      padding: 1px 5px;
    }
  }

}
