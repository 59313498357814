.view-display-id-manage_contents {
  .view-filters {

    .form-item label {
      margin-bottom: 7px;
    }

    textarea,
    input[type="email"],
    input[type="number"],
    input[type="password"],
    input[type="tel"],
    input[type="url"],
    input[type="text"] {
      font-size: 16px;
      border: 1px solid;
      box-shadow: 0 0 1px transparent;
      padding: 5px;

      &:focus {
        outline: none !important;
        box-shadow: 0 0 1px $color-red-1;
      }

    }

    .selectize-control.single {
      min-width: 150px;
    }

    .form-submit {
      color: white;
      font-size: 16px;
      font-weight: 500;
      background: $color-grey-1;
      border: none;
      cursor: pointer;
      padding: 8px 20px;
      margin-top: 1em;

      &[disabled] {
        background: $color-grey-2;
      }

      &:not([disabled]):hover {
        background: $color-red-1;
      }

      &.alert {
        background: $color-red-1;

        &:hover {
          background: $color-grey-1;
        }

      }

    }

    form {

      > div > fieldset,
      > div > div {
        margin-top: 1em;
        margin-bottom: 3em;
      }

      fieldset {

        &.collapsed {

          legend a {
            color: $color-red-1;
          }

        }
      }

    }

    .form-actions {

      input {
        margin-right: 15px;

        &:last-child {
          margin-right: 0;
        }

      }

    }
  }

  .view-content {

    .sticky-header {
      top: 40px !important;
      z-index: 2;
    }

    .fieldset-wrapper {
      > * {
        margin-right: 10px;
      }
    }

    .form-submit {
      color: white;
      font-size: 16px;
      font-weight: 500;
      background: $color-grey-1;
      border: none;
      cursor: pointer;
      padding: 8px 20px;
      margin-top: 1em;

      &[disabled] {
        background: $color-grey-2;
      }

      &:not([disabled]):hover {
        background: $color-red-1;
      }

      &.alert {
        background: $color-red-1;

        &:hover {
          background: $color-grey-1;
        }

      }

    }

    table {
      tr {
        th {
          padding: 10px;
        }
      }

      tr {

        &.even {
          background-color: white;
          border-bottom: none;
        }

        &.odd {
          background-color: $color-grey-4;
          border-bottom: none;
        }

        td {
          padding: 10px;

          .lang-link {
            text-indent: -9999em;
            display: inline-block;
            width: 16px;
            height: 11px;
            vertical-align: middle;

            &.lang-not-translated {
              opacity: .4;
            }

            &.lang-es {
              background: url(image-url("flag-es-16x11.png")) no-repeat center;
            }

            &.lang-pt, &.lang-pt-br {
              background: url(image-url("flag-pt-16x11.png")) no-repeat center;
            }

            &.lang-en {
              background: url(image-url("flag-gb-16x11.png")) no-repeat center;
            }
          }

        }
      }
    }
  }
}
