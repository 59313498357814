.node--landing-page--full {
  position: relative;
  background: #fff;

  .share-social-links {
    position: absolute;
    top: -26px;
    right: 0;

    @include breakpoint ($desk) {
      top: -19px;
      right: -30px;
    }

  }

  .edit-content-layout {
    background: green url(image-url("layout-trigger.svg")) no-repeat center;
    left: calc(100% - 62px);
    width: 60px;
    height: 60px;
    background-size: 44px;
    transition: left 200ms;
    text-indent: -10000em;
    position: absolute;
    top: 10px;
    border-radius: 0 4px 4px 0;
    z-index: -1;

    &.opened {
      z-index: 2;
    }
  }

  > .content:not(.layout-editing) {
    background: #fff;
    border: 2px dashed transparent;
    transition: border 200ms;

    &:hover {
      border: 2px dashed green;

      .edit-content-layout {
        left: calc(100%);

        &:hover {
          opacity: .8;
        }

      }

    }
  }

}

// Small visual hack. Prevent the layout edition
// border get shown for anon users.
body.not-logged-in {
  .node--landing-page--full {
    > .content:not(.layout-editing) {
      border: none;

      &:hover {
        border: none;
      }

    }
  }

}