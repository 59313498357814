// The real grid is twitter bootstrap. @see sass/external-libraries/external-libraries.scss
// for built in customizations.

// Since we configured the tbs grid with $grid-gutter-width: 30px; we need to apply the
// same spacing vertically since tbs grid does not provide it.
.row {
  margin-bottom: 30px;

  &:last-child {
    margin-bottom: 0;
  }

  .col {
    margin-bottom: 30px;

    @include breakpoint($tab) {
      margin-bottom: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }

  }

}
