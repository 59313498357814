#block-system-user-menu {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  background: black;

  > h2 {
    display: none;
  }

  > .content {

    // Level 0
    > ul {
      margin: 0;
      padding: 0;
      @include clearfix;

      > li {
        margin: 0;
        padding: 0;
        float: left;
        list-style: none;
        position: relative;
        background: black;

        &:hover {
          background: #0074bd;

          & > a + ul, & > span + ul {
            display: block;
          }
        }

        &.active-trail {
          background: #004789;
        }

        > a, > span {
          display: block;
          color: white;
          font-size: 16px;
          font-weight: normal;
          line-height: 1.2;
          cursor: pointer;
          padding: 10px 20px;

          &.nolink {
            cursor: auto;
          }

          // Level 1
          & + ul {
            margin: 0;
            padding: 0;
            position: absolute;
            display: none;

            > li {
              margin: 0;
              padding: 0;
              list-style: none;
              background: black;

              &.expanded {

                &:hover {

                  > ul {
                    display: block;
                  }

                }

                // Level 2
                > ul {
                  display: none;
                  padding: 0;
                  margin: 0;

                  li {
                    list-style: none;
                    padding: 0;
                    margin: 0;

                    &:not(.active-trail) {

                      a, span {

                        &:hover {
                          background: #002c70;
                        }

                      }

                    }

                    &.active-trail {

                      > a,
                      > span {
                        background: #002c70;

                        &:hover {
                          background: #002c70;
                        }

                      }

                    }

                    a, span {
                      display: block;
                      padding: 10px 20px 10px 30px;
                      color: white;
                      font-weight: normal;
                      white-space: nowrap;
                    }

                  }
                }

              }

              &:hover {
                background: #0074bd;
              }

              &.active-trail {
                background: #004789;
              }

              a, span {
                display: block;
                padding: 10px 20px;
                color: white;
                font-weight: normal;
                white-space: nowrap;
              }

            }
          }

        }

        // Last 2 elements goes right.
        &:nth-last-child(-n+2) {
          float: right;
        }
      }
    }
  }

}
